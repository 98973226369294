export const MENU = 'MENU';
export const MENUMODAL = 'MENUMODAL';
export const TOPBAR = 'TOPBAR';
export const USERDATA = 'USERDATA';
export const PRICES = 'PRICES';
export const PINVERIFY = 'PINVERIFY';
export const TRANSFER = 'TRANSFER';

export const menu = () => ({
    type: MENU
  });

export const menuModal = () => ({
    type: MENUMODAL
  });

export const topBar = (data) => ({
    type: TOPBAR,
    payload: data
  })

export const userData = (data) => ({
    type: 'USERDATA',
    payload: data
  })

  export const prices = (data) => ({
    type: 'PRICES',
    payload: data
  })

  export const pinverify = (data) => ({
    type: 'PINVERIFY',
    payload: data
  })

  export const transfer = (data) => ({
    type: 'TRANSFER',
    payload: data
  })