import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, LineElement, CategoryScale, LinearScale, PointElement, TimeScale } from 'chart.js';
import 'chart.js/auto';

Chart.register(LineElement, CategoryScale, LinearScale, PointElement, TimeScale);

const AreaChart = () => {
  // Extract the data from the provided structure
  const prices = [
    [1723717343952, 0.1316413024316583],
    [1723717572994, 0.1317082420195889],
    [1723717855897, 0.1318312553433061],
    [1723718160534, 0.13171143755270162],
    [1723718424513, 0.13167356862446197],
    [1723718720515, 0.13145932864248963],
    [1723719059223, 0.13163644883652362],
    [1723719385472, 0.13171749194130883]
  ];

  const data = {
    labels: prices.map(price => new Date(price[0]).toLocaleTimeString()), // Convert timestamps to readable time
    datasets: [
      {
        label: 'Price',
        data: prices.map(price => price[1]),
        fill: true,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'minute',
        },
      },
      y: {
        beginAtZero: false,
      },
    },
  };

  return (
    <div>
      <h2>Area Chart</h2>
      <Line data={data} options={options} />
    </div>
  );
};

export default AreaChart;
