import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { coins } from '../../constants/coins';
import QRCode from "react-qr-code";
import 'swiper/css';
import './ReceiveContent.css'; // Import the custom CSS file
import { Copy } from 'react-bootstrap-icons';
import { Alert, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const ReceiveContent = () => {
  const dt = useSelector((state) => state.menu);
  const [selectedCoinIndex, setSelectedCoinIndex] = useState(null);
  const [address, setAddress] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    if(dt.userData == false){

    }else{
      setAddress(dt.userData.networks[0].address);
    }
  }, []);

  const handleSelectCoin = (index) => {
    setSelectedCoinIndex(index === selectedCoinIndex ? null : index);
    // console.log(coins[index].symbol);
    handleAddress(coins[index].symbol);
  };

  const handleAddress = (d) => {
    // console.log(d);
    // console.log(dt.userData.networks[0]);
    if(d == 'EDN'){
      setAddress(dt.userData.networks[0].address);
    }
    if(d == 'BTC'){
      setAddress(dt.userData.networks[0].btc);
    }
    if(d == 'ETH'){
      setAddress(dt.userData.networks[0].eth);
    }
    if(d == 'BSC'){
      setAddress(dt.userData.networks[0].bsc);
    }
    if(d == 'TRX'){
      setAddress(dt.userData.networks[0].tron);
    }
    if(d == 'LTC'){
      setAddress(dt.userData.networks[0].ltc);
    }
    if(d == 'DOGE'){
      setAddress(dt.userData.networks[0].doge);
    }
    if(d == 'ARB'){
      setAddress(dt.userData.networks[0].arb);
    }
    if(d == 'USDT'){
      setAddress(dt.userData.networks[0].tron);
    }
    if(d == 'MATIC'){
      setAddress(dt.userData.networks[0].matic);
    }
    if(d == 'BNB'){
      setAddress(dt.userData.networks[0].bsc);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card shadow-sm mb-4">
            <ul className="list-group list-group-flush bg-none">
              {coins.map((user, index) => (
                
                  <li
                    key={user.id}
                    className={`list-group-item ${selectedCoinIndex === index ? 'active' : ''}`}
                    
                  >
                    <div className="row" onClick={() => handleSelectCoin(index)}>
                      <div className="col-auto">
                        <div className="card">
                          <div className="card-body p-1">
                            <figure className="avatar avatar-44 rounded-15">
                              <img src={user.image} alt={user.name} />
                            </figure>
                          </div>
                        </div>
                      </div>
                      <div className="col px-0">
                        <p>
                          {user.name}
                          <br />
                          <small className="text-secondary">
                            {user.symbol == "EDN" ? <>
                            {t('price')}: {dt.userData && dt.userData.price ? dt.userData.price.toFixed(4) : 0}
                            </>:<>{t('price')}: {dt.prices[user.symbol]?.USD || 0}</>}
                            
                          </small>
                        </p>
                      </div>
                      <div className="col-auto text-end">
                        <p>
                          <small className="text-secondary">
                            <div>
                              <p>{t('balance')}</p>
                              <p>
                                {user.symbol == 'EDN' ? (
                                    <>
                                    {dt.userData && dt.userData.balance ? dt.userData.balance.toFixed(4) : 0} {user.symbol}
                                    </>
                                ) : (
                                    <>0 {user.symbol}</>
                                )}
                                </p>
                            </div>
                          </small>
                        </p>
                      </div>
                    </div>
                    
                    {/* Conditional rendering of the selected coin details with animation */}
                    <div
                      className={`details ${selectedCoinIndex === index ? 'open' : ''}`}
                    >
                       <div className="text-center">
                       
                        <div style={{ height: "auto", margin: "0 auto", maxWidth: 150, width: "100%" }}>
                        
                        <QRCode
                            size={512}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={address}
                            viewBox={`0 0 256 256`}
                        />
                        </div>
                        <p className="text-secondary mb-4" style={{ fontSize: 14 }}>
                        {address} <Copy style={{ color : 'black', cursor: 'hand' }} onClick={() => {navigator.clipboard.writeText(address); toast.success("Copied!")}}/>
                        </p>
                        </div>
                        <Alert variant="info" className='text-center'>
                          <Alert.Heading>{t('notice')}</Alert.Heading>
                          <p>{t('depositnotea')} {user.name} ({user.symbol}) {t('on')} {user.network} {t('network')}</p>
                        </Alert>
                    </div>
                  </li>
                
              ))}
            </ul>
          </div>
        </div>
      </div>

      <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            // transition= "bounce"    
            />
    </>
  );
};

export default ReceiveContent;
