import React, { useState, useRef, useEffect } from 'react';
import './MnemonicEntry.css'; // Import the custom CSS
import withSessionCheck from '../../middleware/withauthcheck';
import { fetchData } from "../../helpers/security";
import { postHelper } from "../../helpers/apiHelper";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function MnemonicEntry() {
    const [mnemonicWords, setMnemonicWords] = useState([
        'word1', 'word2', 'word3', 'word4', 'word5', 'word6',
        'word7', 'word8', 'word9', 'word10', 'word11', 'word12'
    ]);

    const [words, setWords] = useState(Array(12).fill(''));
    const [usedWords, setUsedWords] = useState(new Set()); // Track used words
    const inputRefs = useRef([]);

    const navigate = useNavigate();
    const { t } = useTranslation();

    // Shuffle words
    const shuffleArray = (array) => {
        let shuffledArray = [...array]; // Create a copy of the array to avoid mutating the original
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    };

    useEffect(() => {
        const fetch = async () => {
            const token = fetchData('user');
            const res = await postHelper('user/getMnemonic', {}, token);
            if (res.type === "auth") {
                navigate('/sessionexpired');
            }
            if (res.error) {
                toast(res.msg, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                const shuffledMnemonic = shuffleArray(res.mnemonic.split(' '));
                setMnemonicWords(shuffledMnemonic);
            }
        };

        fetch();
    }, [navigate]);

    const handleWordClick = (word) => {
        const index = words.findIndex(w => w === '');
        if (index !== -1) {
            const newWords = [...words];
            newWords[index] = word;
            setWords(newWords);
            setUsedWords(prev => new Set(prev).add(word)); // Mark the word as used

            // Only focus the next input on non-mobile devices to avoid opening the keyboard
            if (index < 11 && !isMobileDevice()) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    // Function to detect mobile devices
    const isMobileDevice = () => {
        return /Mobi|Android/i.test(navigator.userAgent);
    };

    const handleChange = (e, index) => {
        const value = e.target.value.trim();
        const newWords = [...words];

        // If a word is removed, also remove it from the usedWords set
        if (newWords[index] !== '' && !mnemonicWords.includes(value)) {
            setUsedWords(prev => {
                const newSet = new Set(prev);
                newSet.delete(newWords[index]);
                return newSet;
            });
        }

        newWords[index] = value;
        setWords(newWords);

        // If the word is in the mnemonicWords, mark it as used
        if (mnemonicWords.includes(value)) {
            setUsedWords(prev => new Set(prev).add(value));
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && words[index] === '' && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleClearInput = (index) => {
        const newWords = [...words];
        const wordToRemove = newWords[index];

        if (usedWords.has(wordToRemove)) {
            setUsedWords(prev => {
                const newSet = new Set(prev);
                newSet.delete(wordToRemove);
                return newSet;
            });
        }

        newWords[index] = '';
        setWords(newWords);
        inputRefs.current[index].focus();
    };

    const handleClearAll = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('sessionExpiry');
        navigate('/success');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const mnemonic = words.join(' ');
        const res = await postHelper('user/mnemonicverify', { mnemonic }, fetchData('user'));
        if (res.type === "auth") {
            navigate('/sessionexpired');
        }
        if (res.error) {
            toast(res.msg, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast(res.msg, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                onClose: () => handleClearAll(),
            });
        }
    };

    return (
        <div className="col-11 col-sm-11 col-md-8 col-lg-6 mx-auto align-self-center py-4">
            <h1 className="mb-3">
                <span className="text-secondary fw-light">{t('enteryour')}</span><br />{t('12menu')}
            </h1>
            <p className="text-secondary mb-4">{t('enter12mnemonic')}</p>

            <form onSubmit={handleSubmit}>
                <div className="row mb-4">
                    {words.map((word, index) => (
                        <div key={index} className="col-6 col-md-3 mb-2 d-flex align-items-center position-relative">
                            <label className="form-label mb-0 me-2">{index + 1}.</label>
                            <input
                                type="text"
                                className="form-control"
                                value={word}
                                onChange={(e) => handleChange(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                ref={(el) => (inputRefs.current[index] = el)}
                            />
                            {word && (
                                <button
                                    type="button"
                                    className="btn-closea position-absolute end-0 top-50 translate-middle-y me-2"
                                    onClick={() => handleClearInput(index)}
                                >X</button>
                            )}
                        </div>
                    ))}
                </div>

                <div className="row mb-4">
                    {mnemonicWords.map((word, index) => (
                        <div key={index} className="col-6 col-md-3 mb-2">
                            <button
                                type="button"
                                className={`btn w-100 ${usedWords.has(word) ? 'btn-success' : 'btn-outline-secondary'}`}
                                onClick={() => handleWordClick(word)}
                                disabled={usedWords.has(word)}
                            >
                                {word}
                            </button>
                        </div>
                    ))}
                </div>

                <div className="d-grid">
                    <button type="submit" className="btn btn-primary btn-lg">
                        {t('submit')}
                    </button>
                </div>
            </form>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
}

export default withSessionCheck(MnemonicEntry);
