import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { postHelper } from '../../helpers/apiHelper';
import { storedata } from '../../helpers/security';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function SignIn() {
    // Initialize state for form inputs
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        // Add form validation or submission logic here
        const tid = toast.loading("Please wait...", )
        if (password.length < 6) {
            setPasswordError('Password must be at least 6 characters');
        } else {
            setPasswordError('');
            // Submit form or perform other actions
            // console.log('Form submitted with:', { username, password });
            const data = { 
                emailPhone: username,
                password: password
             };
            const res = await postHelper('user/login', data, "");
            
            if(res.error){
                toast.update(tid, { render: res.msg, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false })
            }else{
                storedata("user", res.token, "login");
                if(res.tfa){
                    navigate("/tfa");
                }else{
                    if(res.seed){
                        console.log("transfer to seed");
                        toast.update(tid, { render: res.msg, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false,onClose: () => navigate("/seed") })
                    }else{
                        // console.log("transfer to dashboard");
                        toast.update(tid, { render: res.msg, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false,onClose: () => navigate("/dashboard") })
                    }
            
                }
            }
        }
    };

    return (
        <div className="col-11 col-sm-11 col-md-6 col-lg-5 col-xl-3 mx-auto align-self-center py-4">
            <h1 className="mb-4">
                <span className="text-secondary fw-light">{t('signin')}</span><br />{t('signintag')}
            </h1>

            <form onSubmit={handleSubmit}>
                {/* Username Input */}
                <div className="form-group form-floating mb-3 is-valid">
                    <input
                        type="text"
                        className="form-control"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        id="email"
                        placeholder={t('username')}
                    />
                    <label className="form-control-label" htmlFor="email">
                        {t('username')}
                    </label>
                </div>

                {/* Password Input */}
                <div className={`form-group form-floating mb-3 ${passwordError ? 'is-invalid' : 'is-valid'}`}>
                    <input
                        type="password"
                        className="form-control"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        id="password"
                        placeholder={t('password')}
                    />
                    <label className="form-control-label" htmlFor="password">
                        {t('password')}
                    </label>
                    {passwordError && (
                        <button
                            type="button"
                            className="text-danger tooltip-btn"
                            data-bs-toggle="tooltip"
                            data-bs-placement="left"
                            title={passwordError}
                            id="passworderror"
                        >
                            <i className="bi bi-info-circle"></i>
                        </button>
                    )}
                </div>

                {/* Forgot Password Link */}
                <p className="mb-3 text-end">
                    <Link to="/recover" className="text-secondary">
                        {t('forgotPassword')}
                    </Link>
                </p>

                {/* Submit Button */}
                <div className="d-grid">
                    <button type="submit" className="btn btn-default btn-lg shadow-sm">
                        {t('login')}
                    </button>
                </div>
            </form>
            <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            // transition= "bounce"    
            />
        </div>
    );
}

export default SignIn;
