import React, { useEffect, useState } from 'react';
import {Lock, Key, Check2Circle, ShieldCheck, QrCode, Copy} from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { postHelper } from '../helpers/apiHelper';
import { fetchData } from '../helpers/security';
import QRCode from 'react-qr-code';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
const AccountSettings = () => {
    const [activeSection, setActiveSection] = useState(null);

    const menu = useSelector((state) => state.menu.userData);
    const { t } = useTranslation();
    const handleSectionClick = (section) => {
        setActiveSection(activeSection === section ? null : section); // Toggle the section
    };

    return (
        <div className="card shadow-sm mb-4">
            <div className="card-body">
                {/* Header Section */}
                <div className="d-flex align-items-center mb-4">
                    <img
                        src="assets/images/user1.jpg" // Replace with actual user avatar
                        alt="User Avatar"
                        className="rounded-circle"
                        style={{ width: '60px', height: '60px', marginRight: '15px' }}
                    />
                    <div>
                        <h5 className="mb-1">{menu ? `${menu.data.firstname} ${menu.data.lastname}`: 'Loading'}</h5>
                        <p className="mb-0 text-secondary">{t('userid')}: {menu ? menu.data.username: 'Loading'}</p>
                        <p className="mb-0 text-secondary">{t('email')}: {menu ? menu.data.email: 'Loading'}</p>
                    </div>
                    {/* <div className="ms-auto">
                        <button className="btn btn-outline-primary btn-sm me-2">
                            <i className="fas fa-envelope"></i> Notifications
                        </button>
                        <button className="btn btn-outline-secondary btn-sm">
                            <i className="fas fa-cog"></i> Logout
                        </button>
                    </div> */}
                </div>

                {/* Account Settings Section */}
                <h6 className="title">{t('accountsettings')}</h6>
                <ul className="list-group list-group-flush">
                    <li 
                        className="list-group-item d-flex justify-content-between align-items-center"
                        onClick={() => handleSectionClick('changePassword')}
                    >
                        <span>{t('changepass')}</span>
                        <a className="btn btn-primary btn-sm">{t('update')}</a>
                    </li>
                    {activeSection === 'changePassword' && (
                        <div className="form-container">
                            <ChangePasswordForm />
                        </div>
                    )}

                    <li 
                        className="list-group-item d-flex justify-content-between align-items-center"
                        onClick={() => handleSectionClick('enable2FA')}
                    >
                        <span>{t('tfaenable')}</span>
                        {menu.data && menu.data.tfa ? <a className="btn btn-success btn-sm">{t('enabled')}</a>: <a className="btn btn-primary btn-sm">{t('disabled')}</a>}
                        
                    </li>
                    {activeSection === 'enable2FA' && (
                        <div className="form-container">
                            <Enable2FAForm />
                        </div>
                    )}

                    <li 
                        className="list-group-item d-flex justify-content-between align-items-center"
                        onClick={() => handleSectionClick('updateEmail')}
                    >
                        <span>{t('viewmnemonic')}</span>
                        <a className="btn btn-primary btn-sm">{t('view')}</a>
                    </li>
                    {activeSection === 'updateEmail' && (
                        <div className="form-container">
                            <UpdateEmailForm />
                        </div>
                    )}

<li 
                        className="list-group-item d-flex justify-content-between align-items-center"
                        onClick={() => handleSectionClick('updateEmail')}
                    >
                        <span>{t('reset')} Pin</span>
                        <Link to="/setpin" className="btn btn-primary btn-sm">{t('view')}</Link>
                    </li>
                </ul>
            </div>
            <ToastContainer />
        </div>
    );
};

const ChangePasswordForm = () => {
    const { t } = useTranslation();
    return(
    <div className="d-flex justify-content-center align-items-center" style={{ padding: '20px 0' }}>
        <div className="card shadow-lg p-4" style={{ width: '400px', maxWidth: '100%' }}>
            <h5 className="mb-4 text-primary text-center">{t('comingsoone')}</h5>
            {/* <form>
                <div className="mb-3 position-relative">
                    <label className="form-label" htmlFor="currentPassword">
                        Current Password
                    </label>
                    <div className="input-group">
                        <span className="input-group-text bg-light">
                            <Lock />
                        </span>
                        <input
                            type="password"
                            className="form-control"
                            id="currentPassword"
                            placeholder="Enter current password"
                        />
                    </div>
                </div>
                <div className="mb-3 position-relative">
                    <label className="form-label" htmlFor="newPassword">
                        New Password
                    </label>
                    <div className="input-group">
                        <span className="input-group-text bg-light">
                            <Key />
                        </span>
                        <input
                            type="password"
                            className="form-control"
                            id="newPassword"
                            placeholder="Enter new password"
                        />
                    </div>
                </div>
                <div className="mb-4 position-relative">
                    <label className="form-label" htmlFor="confirmNewPassword">
                        Confirm New Password
                    </label>
                    <div className="input-group">
                        <span className="input-group-text bg-light">
                            <Key />
                        </span>
                        <input
                            type="password"
                            className="form-control"
                            id="confirmNewPassword"
                            placeholder="Confirm new password"
                        />
                    </div>
                </div>
                <button type="submit" className="btn btn-primary btn-block w-100">
                    <i className="fas fa-save me-2"></i>Update Password
                </button>
            </form> */}
        </div>
    </div>
)};

const Enable2FAForm = () => {
    const menu = useSelector((state) => state.menu.userData);
    const token = fetchData('user');
    const [secret, setSecret] = useState(false);
    const [tfa, setTfa] = useState(false);
    const [enabled, setEnabled] = useState(false);
    const [code, setCode] = useState('');
    const { t } = useTranslation();
    const generateCode = async() => { 
        const secret = await postHelper('user/generate2fa', {}, token);
        setTfa(true);
        setSecret(secret);
        toast.info('Please Scan with any authenticator app to enable 2FA');
     }

     const verifyCode = async() => {
        const result = await postHelper('user/enable2fa', { authCode: code }, token);
        if (result) {
            setEnabled(true);
            toast.success('2FA enabled successfully');
        }else{
            alert('Invalid code');
        }
     }

     const disable2fa = async() => {
        const result = await postHelper('user/disable2fa', {}, token);
        if (result) {
            setEnabled(false);
            toast.success('2FA disabled successfully');
        }
     }

     useEffect(() => {
        if (menu.data.tfa) {
            setEnabled(true);
        }}, [menu]);
    return(
    
    <div className="d-flex justify-content-center align-items-center" style={{ padding: '20px 0' }}>
        <div className="card shadow-lg p-4" style={{ width: '400px', maxWidth: '100%' }}>
            <h5 className="mb-4 text-primary text-center">{t('tfaenablednote')}</h5>
            {!enabled ? 
            
            <>
            {!tfa ?
            <>
            <p className="text-center">Your 2FA secret is not set.</p> 
            <a className='btn btn-primary btn-block w-100' onClick={() => generateCode()}>{t('set')} 2FA</a>
            </>
            : 
            <>
                <p className="text-center">{t('scantfa')}</p>
                <div className="mb-4 text-center">
                    {
                        !secret.error &&
                        <img src={secret.qrCode} />

                        
                    }
                </div>
                <div className="mb-3 text-center">
                <a style={{fontSize: '10px'}}>{secret.secret} <Copy onClick={() => {navigator.clipboard.writeText(secret.secret); toast.success("Copied!")}}/></a>
                </div>
                <div className="mb-3 position-relative">
                    <label className="form-label" htmlFor="authCode">
                        {t('Enter Code from App')}
                    </label>
                    <div className="input-group">
                        <span className="input-group-text bg-light">
                            <ShieldCheck />
                        </span>
                        <input
                            type="text"
                            className="form-control"
                            id="authCode"
                            placeholder="Enter authentication code"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                        />
                    </div>
                </div>
                <button type="submit" className="btn btn-primary btn-block w-100" onClick={() => verifyCode()}>
                    <Check2Circle /> {t('enable')} 2FA
                </button>
            </>
            }
            </>:
            <>
            <p className="text-center">{t('tfaenabled')}</p>
            <Button variant="danger" className="btn-block w-100" onClick={() => disable2fa()} > {t('disable')} 2FA</Button>
            </>
            }
        </div>
    </div>
)};

const UpdateEmailForm = () => {
    const menu = useSelector((state) => state.menu.userData);
    const { t } = useTranslation();
    return(
    
    <div className="form-content">
        <h5>{t('mnemonicphrase')}</h5>
        <form>
            <div className="mb-3">
                {/* <label className="form-label">New Email Address</label> */}
                {/* <input type="email" className="form-control" disabled value={menu.data.mnemonic} /> */}
                {/* <textarea style={{width: '100%'}}>{menu.data.mnemonic}</textarea> */}

                <Container className="mt-4">
                    <Row>
                        {menu.data.mnemonic.split(' ').map((word, index) => (
                        <Col key={index} xs={6} md={4} className="mb-3">
                            <Button variant="primary" className="w-100" style={{fontSize: '12px'}}>
                            {index + 1}. {word}
                            </Button>
                        </Col>
                        ))}
                    </Row>
                </Container>
            </div>
            {/* <button type="submit" className="btn btn-primary">Update Email</button> */}
        </form>
    </div>
)};

export default AccountSettings;
