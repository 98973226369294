import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Card, Dropdown } from 'react-bootstrap';
import './SwapPage.css';
import { coins } from '../constants/coins';

const SwapPage = () => {
  const [fromImage, setFromImage] = useState(coins[0].image);
  const [fromData, setFromData] = useState(coins[0].name);
  const [fromValue, setFromValue] = useState('');
  const [toImage, setToImage] = useState(coins[1].image);
  const [toData, setToData] = useState(coins[1].name);
  const [toValue, setToValue] = useState('');

  const handleSwap = () => {
    alert(`Swapped ${fromValue} of ${fromData} to ${toValue} of ${toData}`);
  };

  return (
    <Container className="swap-page d-flex align-items-center justify-content-center">
      <Card className="swap-box p-4">
        <h2 className="text-center mb-4">Swap</h2>
        <Form>
          <Form.Group as={Row} className="mb-3" controlId="formFrom">
            <Col>
              <Dropdown onSelect={(selectedKey) => {
                setFromImage(coins[selectedKey].image);
                setFromData(coins[selectedKey].name);
              }} className="w-100">
                <Dropdown.Toggle variant="light" id="dropdown-basic" className="d-flex align-items-center w-100">
                  <img src={fromImage} alt="Logo" className="logo-img" />
                  <span className="ml-2">{fromData}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-100">
                  {coins.map((coin, index) => (
                    <Dropdown.Item key={index} eventKey={index} className="d-flex align-items-center">
                      <img src={coin.image} alt={coin.name} className="logo-img" style={{ width: 24, marginRight: 8 }} />
                      {coin.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formTo">
            <Col>
              <Dropdown onSelect={(selectedKey) => {
                setToImage(coins[selectedKey].image);
                setToData(coins[selectedKey].name);
              }} className="w-100">
                <Dropdown.Toggle variant="light" id="dropdown-basic" className="d-flex align-items-center w-100">
                  <img src={toImage} alt="Logo" className="logo-img" />
                  <span className="ml-2">{toData}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-100">
                  {coins.map((coin, index) => (
                    <Dropdown.Item key={index} eventKey={index} className="d-flex align-items-center">
                      <img src={coin.image} alt={coin.name} className="logo-img" style={{ width: 24, marginRight: 8 }} />
                      {coin.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formAmount">
            <Col>
              <Form.Control
                type="text"
                placeholder="0.00"
                value={fromValue}
                onChange={(e) => setFromValue(e.target.value)}
                className="mb-2"
              />
              <small>150.00</small>
            </Col>
          </Form.Group>

          <Button variant="primary" className="w-100" onClick={handleSwap}>
            Swap
          </Button>
        </Form>
      </Card>
    </Container>
  );
};

export default SwapPage;
