import React,  { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { FaList, FaUserCircle, FaWindowMinimize } from 'react-icons/fa';
import {BoxArrowRight, Globe2} from 'react-bootstrap-icons';
import Logo from '../assets/img/logo.png';
import { useSelector, useDispatch } from 'react-redux'
import {menu} from '../reducers/actions/menuaction'
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// import { Toast, ToastContainer } from 'react-toastify';

const Header = () => {
  const state = useSelector((state) => state.menu)
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

    const logoutFunction = () =>{
      localStorage.removeItem("user");
      navigate("/signin");
    }

    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };

    return (
        <header className={`header position-fixed ${state.isTopBarOpen ? 'active' : ''}`}>
        <div className="row">
          <div className="col-auto">
            <a href="javascript:void(0)" target="_self" className={`btn btn-light btn-44 menu-btn`} onClick={() => dispatch(menu())}>
              <FaList />
            </a>
          </div>
          <div className="col text-center">
            <div className="logo-small">
              <img src={Logo} alt="" />
              <h5>
                <span className="text-secondary fw-light">EDEN</span><br />
                Wallet
              </h5>
            </div>
          </div>
          <div className="col-auto">
          <DropdownButton
            id="dropdown-basic-button"
            title={<Globe2 />}
            variant="light"
            className=""
            // style={{color: "#fff", backgroundColor: "#000"}}
          >
            <Dropdown.Item onClick={() => changeLanguage('en')}>English</Dropdown.Item>
          <Dropdown.Item onClick={() => changeLanguage('es')}>Español (Spanish)</Dropdown.Item>
          <Dropdown.Item onClick={() => changeLanguage('hn')}>हिन्दी (Hindi)</Dropdown.Item>
          <Dropdown.Item onClick={() => changeLanguage('ar')}>العربية (Arabic)</Dropdown.Item>
          <Dropdown.Item onClick={() => changeLanguage('zh')}>中文 (Chinese)</Dropdown.Item>
          <Dropdown.Item onClick={() => changeLanguage('de')}>Deutsch (German)</Dropdown.Item>
          {/* <Dropdown.Item onClick={() => changeLanguage('pt')}>Português (Portuguese)</Dropdown.Item> */}
          <Dropdown.Item onClick={() => changeLanguage('ru')}>Русский (Russian)</Dropdown.Item>
          {/* <Dropdown.Item onClick={() => changeLanguage('it')}>Italiano (Italian)</Dropdown.Item> */}
          <Dropdown.Item onClick={() => changeLanguage('ja')}>日本語 (Japanese)</Dropdown.Item>
          <Dropdown.Item onClick={() => changeLanguage('ko')}>한국어 (Korean)</Dropdown.Item>
          <Dropdown.Item onClick={() => changeLanguage('fr')}>Français (French)</Dropdown.Item>
      
          </DropdownButton>
              
            </div>

          {/* <div className="col-auto">
            <a href="#" onClick={logoutFunction} className="btn btn-light btn-44">
              <BoxArrowRight />
              <span className="count-indicator"></span>
            </a>
          </div> */}
        </div>
      </header>
    )
}

export default Header