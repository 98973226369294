import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions">
      <h1>EDEN Terms and Conditions</h1>

      <section>
        <h2>Introduction</h2>
        <p>
          Welcome to EDEN. These Terms and Conditions ("Terms") govern your use of our website, services, and products (collectively, the "Service"). By accessing or using the Service, you agree to be bound by these Terms. If you do not agree with these Terms, please do not use the Service.
        </p>
      </section>

      <section>
        <h2>1. Definitions</h2>
        <p>
          <strong>"EDEN"</strong> refers to our digital currency, including all related services and products.
        </p>
        <p>
          <strong>"User"</strong> refers to any individual or entity accessing or using the Service.
        </p>
        <p>
          <strong>"Wallet"</strong> refers to the digital wallet used to store EDEN.
        </p>
      </section>

      <section>
        <h2>2. Eligibility</h2>
        <p>
          To use the Service, you must be at least 18 years old and have the legal capacity to enter into a binding agreement. By using the Service, you represent and warrant that you meet these requirements.
        </p>
      </section>

      <section>
        <h2>3. Account Registration</h2>
        <p>
          To access certain features of the Service, you may need to register an account. You agree to provide accurate, complete, and current information during the registration process and to update such information as needed. You are responsible for maintaining the confidentiality of your account and password and for all activities that occur under your account.
        </p>
      </section>

      <section>
        <h2>4. Use of Service</h2>
        <p>
          <strong>Compliance:</strong> You agree to use the Service in compliance with all applicable laws and regulations.
        </p>
        <p>
          <strong>Prohibited Activities:</strong> You agree not to engage in any of the following activities:
        </p>
        <ul>
          <li>Unauthorized use of the Service, including unauthorized access to EDEN's systems.</li>
          <li>Any activity that disrupts or interferes with the Service or servers.</li>
          <li>Using the Service for illegal or fraudulent purposes.</li>
        </ul>
      </section>

      <section>
        <h2>5. Transactions</h2>
        <p>
          <strong>Digital Currency Transactions:</strong> All transactions involving EDEN are final and irreversible once confirmed. EDEN is not responsible for any loss due to transaction errors or unauthorized access to your wallet.
        </p>
        <p>
          <strong>Fees:</strong> You may be required to pay transaction fees when using the Service. These fees are disclosed before you complete the transaction and are subject to change.
        </p>
      </section>

      <section>
        <h2>6. Intellectual Property</h2>
        <p>
          All content on the EDEN website, including text, graphics, logos, and software, is the property of EDEN or its licensors and is protected by intellectual property laws. You may not use, reproduce, or distribute any content without our prior written permission.
        </p>
      </section>

      <section>
        <h2>7. Privacy Policy</h2>
        <p>
          Your privacy is important to us. Please refer to our Privacy Policy for information on how we collect, use, and protect your personal data.
        </p>
      </section>

      <section>
        <h2>8. Limitation of Liability</h2>
        <p>
          To the maximum extent permitted by law, EDEN shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Service. EDEN's total liability to you for all claims arising from or related to the Service is limited to the amount you paid to EDEN, if any, for access to and use of the Service.
        </p>
      </section>

      <section>
        <h2>9. Indemnification</h2>
        <p>
          You agree to indemnify and hold harmless EDEN, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses arising out of or in any way connected with your use of the Service or your violation of these Terms.
        </p>
      </section>

      <section>
        <h2>10. Changes to Terms</h2>
        <p>
          EDEN reserves the right to modify or update these Terms at any time. Any changes will be effective immediately upon posting on our website. Your continued use of the Service after the posting of revised Terms constitutes your acceptance of the changes.
        </p>
      </section>

      <section>
        <h2>11. Termination</h2>
        <p>
          EDEN may terminate or suspend your access to the Service at any time, without notice, for conduct that EDEN believes violates these Terms or is harmful to other users of the Service.
        </p>
      </section>

      <section>
        <h2>12. Governing Law</h2>
        <p>
          These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising under or in connection with these Terms shall be resolved exclusively by the courts of [Your Jurisdiction].
        </p>
      </section>

      <section>
        <h2>13. Contact Us</h2>
        <p>
          If you have any questions or concerns about these Terms, please contact us at [Your Contact Information].
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditions;
