import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function withSessionCheck(WrappedComponent) {
    return function SessionCheckComponent(props) {
        const navigate = useNavigate();

        useEffect(() => {
            const token = localStorage.getItem('user'); // Assume a session token is stored
            const sessionExpiry = localStorage.getItem('sessionExpiry'); // Store the expiry time
            const now = new Date().getTime();

            if (!token || (sessionExpiry && now > sessionExpiry)) {
                // Session expired or no token found, redirect to session expiry page
                localStorage.removeItem('user');
                localStorage.removeItem('sessionExpiry');
                navigate('/sessionexpired');
            }
        }, [navigate]);

        return <WrappedComponent {...props} />;
    };
}

export default withSessionCheck;
