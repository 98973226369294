import React, { useState } from "react";
import countries from "../../constants/country";
import { InfoCircle, CheckCircle } from "react-bootstrap-icons";
import { postHelper } from "../../helpers/apiHelper";
import {storedata, fetchData} from "../../helpers/security";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

const Signup = () => {
  const { t} = useTranslation();
  const navigate = useNavigate();
  const [form, setForm] = useState({
    country: '',
    emailPhone: '',
    firstname: '',
    lastname: '',
    password: '',
    confirmPassword: ''
  });

  const [errors, setErrors] = useState({
    emailPhone: '',
    firstname: '',
    lastname: '',
    password: '',
    confirmPassword: ''
  });

  const [valid, setValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState({
    hasCapitalLetter: false,
    hasSpecialChar: false,
    isAlphanumeric: false,
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setForm({
      ...form,
      [id]: value
    });

    // Email validation
    if (id === 'emailPhone') {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      setErrors((prevErrors) => ({
        ...prevErrors,
        emailPhone: emailPattern.test(value) ? '' : 'Invalid email address'
      }));
    }

    // Password validation
    if (id === 'password') {
      const hasCapitalLetter = /[A-Z]/.test(value);
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
      const isAlphanumeric = /[0-9]/.test(value);

      setPasswordValid({
        hasCapitalLetter,
        hasSpecialChar,
        isAlphanumeric,
      });

    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     password: hasCapitalLetter && hasSpecialChar && isAlphanumeric
    //       ? ''
    //       : 'Password must have at least one capital letter, one special character, and be alphanumeric'
    //   }));
    }

    // Confirm password validation
    if (id === 'confirmPassword') {
      setValid(value === form.password);
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: value === form.password ? '' : 'Passwords do not match'
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for empty fields
    const newErrors = {};
    Object.keys(form).forEach((key) => {
      if (!form[key]) {
        newErrors[key] = 'This field is required';
      }
    });

    setErrors(newErrors);

    if (
      !Object.values(newErrors).some((error) => error) && // No empty fields
      valid &&
      passwordValid.hasCapitalLetter &&
      passwordValid.hasSpecialChar &&
      passwordValid.isAlphanumeric &&
      !errors.emailPhone
    ) {
      // Handle the form submission (e.g., send data to an API)
      // console.log('Form submitted:', form);
      const tid = toast.loading("Please wait...", )
      const response = await postHelper('user/register', form, "none");
      
      
        if(response.error){
          // alert(response.error);
          toast.update(tid, { render: response.msg, type: "error", isLoading: false, autoClose: 5000, hideProgressBar: false });
        }else{
          // successfully registered
          storedata("user", response.token, "register");
          toast.update(tid, { render: response.msg, type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false, onClose: () => navigate("/pin") });
          
          // navigate("/pin");
        }
      
    } else {
      // alert("Please ensure all fields are filled correctly and all password requirements are met!");
    }
  };

  return (
    <div className="col-11 col-sm-11 col-md-6 col-lg-5 col-xl-3 mx-auto align-self-center py-4">
      <h1 className="mb-4">
        <span className="text-secondary fw-light">{t('signhead')}</span><br />{t('signsubhead')}
      </h1>
      <form onSubmit={handleSubmit}>
        <div className="form-floating is-valid mb-3">
          <select className="form-control" id="country" value={form.country} onChange={handleChange}>
            {countries.map((country, index) => (
              <option key={index} value={country.name}>{country.name}</option>
            ))}
          </select>
          <label htmlFor="country">{t('country')}</label>
          {errors.country && <div className="text-danger small">{errors.country}</div>}
        </div>

        <div className="form-floating is-valid mb-3">
          <input
            type="email"
            className="form-control"
            value={form.emailPhone}
            placeholder={t('email')}
            id="emailPhone"
            onChange={handleChange}
          />
          <label htmlFor="emailPhone">{t('email')}</label>
          {errors.emailPhone && <div className="text-danger small">{errors.emailPhone}</div>}
        </div>

        <div className="form-floating is-valid mb-3">
          <input
            type="text"
            className="form-control"
            value={form.firstname}
            placeholder={t('firstname')}
            id="firstname"
            onChange={handleChange}
          />
          <label htmlFor="firstname">{t('firstname')}</label>
          {errors.firstname && <div className="text-danger small">{errors.firstname}</div>}
        </div>

        <div className="form-floating is-valid mb-3">
          <input
            type="text"
            className="form-control"
            value={form.lastname}
            placeholder={t('lastname')}
            id="lastname"
            onChange={handleChange}
          />
          <label htmlFor="lastname">{t('lastname')}</label>
          {errors.lastname && <div className="text-danger small">{errors.lastname}</div>}
        </div>

        <div className="form-floating is-valid mb-3">
          <input
            type="password"
            className="form-control"
            value={form.password}
            placeholder={t('password')}
            id="password"
            onChange={handleChange}
          />
          <label htmlFor="password">{t('password')}</label>
          <div className="text-muted small mt-2">
            <ul style={{ fontSize: '12px' }}>
              <li className={passwordValid.hasCapitalLetter ? 'text-success' : 'text-danger'}>
                {t('capitalcheck')}
              </li>
              <li className={passwordValid.hasSpecialChar ? 'text-success' : 'text-danger'}>
                {t('specialcheck')}
              </li>
              <li className={passwordValid.isAlphanumeric ? 'text-success' : 'text-danger'}>
                {t('alphanumeric')}
              </li>
            </ul>
          </div>
          {errors.password && <div className="text-danger small">{errors.password}</div>}
        </div>

        <div className={`form-floating mb-3 ${valid ? 'is-valid' : 'is-invalid'}`}>
          <input
            type="password"
            className="form-control"
            value={form.confirmPassword}
            placeholder={t('confirmpass')}
            id="confirmPassword"
            onChange={handleChange}
          />
          <label htmlFor="confirmPassword">{t('confirmpass')}</label>
          {valid ? (
            <button
              type="button"
              className="btn btn-link text-success tooltip-btn"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Passwords match"
            >
              <CheckCircle />
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-link text-danger tooltip-btn"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Passwords do not match"
            >
              <InfoCircle />
            </button>
          )}
          {errors.confirmPassword && <div className="text-danger small">{errors.confirmPassword}</div>}
        </div>

        <p className="mb-3">
          <span className="text-muted">{t('regterms')} </span>
          <a href="#">{t('termslink')}</a>
        </p>

        <div className="row">
          <div className="col-12 d-grid">
            <button className="btn btn-default btn-lg shadow-sm">{t('register')}</button>
          </div>
        </div>
      </form>
      <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            // transition= "bounce"    
            />
    </div>
  );
};

export default Signup;
