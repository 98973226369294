import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { postHelper } from "../helpers/apiHelper";
import { fetchData } from "../helpers/security";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import withSessionCheck from '../middleware/withauthcheck';
import html2canvas from 'html2canvas';  // Import html2canvas for screenshot functionality
import { useTranslation } from 'react-i18next';

function MnemonicSave() {
    const [mnemonic, setMnemonic] = useState('');
    const [saved, setSaved] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const token = fetchData('user');

        const fetch = async () => {
            
            const res = await postHelper('user/getMnemonic', {}, token);
            if (res.type === "auth") {
                navigate('/sessionexpired');
            }
            if (res.error) {
                toast(res.msg, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                setMnemonic(res.mnemonic);
            }
        };

        if (!token) {
            navigate('/signin');
        }else{
            fetch();
        }

    }, [navigate]);

    const handleCopy = () => {
        navigator.clipboard.writeText(mnemonic)
            .then(() => {
                setSaved(true);
                toast(t('mnemoniccopied'), {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
            .catch(err => {
                toast.error(t('failedtocopy'), {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            });
    };

    const handleScreenshot = () => {
        html2canvas(document.body).then(canvas => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
            link.download = 'mnemonic-screenshot.png';
            link.click();
            setSaved(true);
        });
    };

    // Split the mnemonic into words
    const mnemonicWords = mnemonic.split(' ');

    return (
        <div className="col-11 col-sm-11 col-md-8 col-lg-6 mx-auto align-self-center py-4">
            <h1 className="mb-3">
                <span className="text-secondary fw-light">{t('storeyour')}</span><br />{t('mnemonicphrase')}
            </h1>
            <p className="text-secondary mb-4">{t('stoeseedtag')}</p>

            {/* Render seed words as buttons */}
            {/* <div className="mb-4 d-flex flex-wrap justify-content-center">
                {mnemonicWords.map((word, index) => (
                    <button
                        key={index}
                        className="btn btn-outline-primary m-2"
                        style={{ fontSize: '12px', justifyContent: 'space-around !important', textTransform: 'lowercase' }}
                    >
                        {index + 1}. {word}
                    </button>
                ))}
            </div> */}

            <div className="row mb-4">
                    {mnemonicWords.map((word, index) => (
                        <div key={index} className="col-6 col-md-3 mb-2">
                            <button
                                type="button"
                                className={`btn w-100 btn-outline-secondary`}
                                // onClick={() => handleWordClick(word)}
                                disabled={true}
                            >
                                {word}
                            </button>
                        </div>
                    ))}
                </div>

            <div className="d-flex justify-content-between mb-4">
                <button type="button" className="btn btn-outline-secondary" onClick={handleCopy}>
                    {t('copyseed')}
                </button>
                <button type="button" className="btn btn-outline-secondary" onClick={handleScreenshot}>
                    {t('screenshot')}
                </button>
            </div>

            <div className="d-grid">
                {!saved ? (
                    <button type="submit" className="btn btn-primary btn-lg" disabled>
                        {t('storecon')}
                    </button>
                ) : (
                    <button type="submit" className="btn btn-primary btn-lg" onClick={() => navigate('/mnemonic')}>
                        {t('storecon')}
                    </button>
                )}
            </div>

            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
}

export default withSessionCheck(MnemonicSave);
