import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import '../assets/css/style.css';
import Header from '../components/Header';
import BalanceAndStats from '../components/home/Balnce';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import MenuModal from '../components/quickmenu';
import ConnectionSwiper from '../components/home/coins';

const FinwallApp = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menu = useSelector((state) => state.menu)

  useEffect(() => {
    // console.log(menu.userData);
  }, [])
  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState);
  };

  return (
          <>
          <BalanceAndStats />
          <ConnectionSwiper />
          </>
  );
};

export default FinwallApp;
