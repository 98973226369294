import React, { useEffect, useState } from 'react';

const CameraAccess = () => {
  const [stream, setStream] = useState(null);
  const [cameraDevices, setCameraDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState('');

  // Function to request camera access and stream
  const getCameraStream = async (deviceId = null) => {
    try {
      const constraints = {
        video: deviceId ? { deviceId: { exact: deviceId } } : true,
        audio: false,
      };

      const userStream = await navigator.mediaDevices.getUserMedia(constraints);
      setStream(userStream);

      // Set the stream to the video element
      const videoElement = document.querySelector('video');
      if (videoElement) {
        videoElement.srcObject = userStream;
      }
    } catch (error) {
      console.error('Error accessing the camera:', error);
      alert('Camera access is required. Please grant the permission.');
    }
  };

  // Function to list available camera devices
  const getCameraDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((device) => device.kind === 'videoinput');
      setCameraDevices(videoDevices);
      if (videoDevices.length > 0) {
        setSelectedDevice(videoDevices[0].deviceId); // Set default device to first camera
      }
    } catch (error) {
      console.error('Error fetching devices:', error);
    }
  };

  // Effect to request camera devices and permission on load
  useEffect(() => {
    getCameraDevices();
    getCameraStream(); // Request default camera on load
  }, []);

  // Handle device selection change
  const handleDeviceChange = (event) => {
    const deviceId = event.target.value;
    setSelectedDevice(deviceId);
    getCameraStream(deviceId); // Fetch stream for selected camera
  };

  return (
    <div className="container mt-4">
      <h2>Camera Access</h2>
      {cameraDevices.length > 0 && (
        <div className="mb-3">
          <label htmlFor="camera-select">Select Camera:</label>
          <select id="camera-select" value={selectedDevice} onChange={handleDeviceChange} className="form-select">
            {cameraDevices.map((device, index) => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.label || `Camera ${index + 1}`}
              </option>
            ))}
          </select>
        </div>
      )}
      <video autoPlay playsInline style={{ width: '100%', height: 'auto' }} />
    </div>
  );
};

export default CameraAccess;
