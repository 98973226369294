import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import QrReader from 'react-weblineindia-qrcode-scanner'

const QrScannerPage = () => {
  const [result, setResult] = useState(null);
  const videoRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getCameraStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        setStream(stream);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (err) {
        setError("Unable to access camera. Please check your permissions.");
        console.error(err);
      }
    };

    getCameraStream();

    // Cleanup function to stop the camera when the component is unmounted
    return () => {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    };
  }, [stream]);

  const handleScan = (data) => {
    if (data) {
      setResult(data.text);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const previewStyle = {
    height: 240,
    width: 320,
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={8} lg={6} className="text-center">
          <h2 className="mb-4">Scan QR Code</h2>
          {error && <p className="error">{error}</p>}
      <video ref={videoRef} autoPlay playsInline style={{ width: '100%', maxWidth: '600px' }} />
    
          {result ? (
            <Alert variant="success" className="mt-3">
              Scanned Result: {result}
            </Alert>
          ) : (
            <Alert variant="info" className="mt-3">
              No QR code scanned yet.
            </Alert>
          )}
          <Button variant="primary" className="mt-4" onClick={() => setResult(null)}>
            Reset
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default QrScannerPage;
