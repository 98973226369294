import CryptoJS from 'crypto-js';
// project encryption key
const secretKey = "e7f622bfb88317b509f80008216a769ba3cfbeacba992e3892b0f719a781cc4a";

const storedata = (key, data, type) => {
    const encryptedData = CryptoJS.AES.encrypt(data, secretKey).toString();
    localStorage.setItem(key, encryptedData);
    if (type === "register") {
        localStorage.setItem("sessionExpiry", new Date().getTime() + (1000 *60 *15));
    }else{
        localStorage.setItem("sessionExpiry", new Date().getTime() + (1000 *60 *60 *24));
    }
}

const fetchData = (key) => {
    const encryptedData = localStorage.getItem(key);
    if (encryptedData) {
        const decryptedData = CryptoJS.AES.decrypt(encryptedData, secretKey).toString(CryptoJS.enc.Utf8);
        return decryptedData;
    }
    return null;
}

const encrypt = (data) => {
    
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
    // console.log(encryptedData);
    return {data: encryptedData};
}

const decrypt = (data) => {
    const decryptedData = CryptoJS.AES.decrypt(data, secretKey).toString(CryptoJS.enc.Utf8);
    return decryptedData;
}

export {storedata, fetchData, encrypt, decrypt}