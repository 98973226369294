import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


import En from './locales/en/translation.json';
import Bn from './locales/bn/translation.json';
import Hn from './locales/hn/translation.json';
import Es from './locales/es/translation.json';
import Fr from './locales/fr/translation.json';
import De from './locales/de/translation.json';
import Cn from './locales/cn/translation.json';
import Ar from './locales/ar/translation.json';
import Pt from './locales/pt/translation.json';
import Ru from './locales/ru/translation.json';
import Ja from './locales/ja/translation.json';
import Ko from './locales/ko/translation.json';


const resources = {
  en: {
    translation: En
  },
  bn: {
    translation: Bn
  },
  hn: {
    translation: Hn
  },
  es: {
    translation: Es
  },
  fr: {
    translation: Fr
  },
  de: {
    translation: De
  },
  zh: {
    translation: Cn
  },
  ar: {
    translation: Ar
  },
  pt: {
    translation: Pt
  },
  ru: {
    translation: Ru
  },
  ja: {
    translation: Ja
  },
  ko: {
    translation: Ko
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
