import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SessionExpired = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const handleLogin = () => {
        // Redirect to login page
        navigate('/signin');
    };

    return (
        <div className="session-expired-page d-flex flex-column justify-content-center align-items-center vh-100">
            <h1>{t('session')}</h1>
            <p>{t('tryagain')}</p>
            <button onClick={handleLogin} className="btn btn-primary">{t('login')}</button>
        </div>
    );
};

export default SessionExpired;
